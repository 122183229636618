import React, { useMemo } from 'react';

import classNames from 'classnames';

import Link from '../../link';
import ZrpMessage from './zrp-message';
import AndesMessageIntervention from '../../../components/intervention/andes-message-intervention/andes-message-intervention';
import { ZrpDisclaimerInterventionPropTypes } from './propTypes/zrp-disclaimer-index';
import { ANDES_MESSAGE_CONFIG, UI_SEARCH_ZRP_DISCLAIMER_NAMESPACE } from '../../../constants';
import { withComponentHandler } from '../../../hocs/with-component-handler';

const interventionProps = { disclaimer: {} };
/**
 * @typedef {Object} ZrpDisclaimerInterventionProps
 * @property {Object} intervention - Los datos de la intervención del disclaimer.
 * @property {Object} intervention.disclaimer - Los datos del disclaimer.
 * @property {string} intervention.disclaimer.zrp_query_text - El texto de la consulta ZRP.
 * @property {string} intervention.disclaimer.className - Las clases CSS adicionales para el componente.
 * @property {string} intervention.disclaimer.query - La consulta asociada al disclaimer.
 * @property {string} intervention.disclaimer.other_sellers_text - El texto relacionado con otros vendedores.
 * @property {Object} intervention.disclaimer.link - Los datos del enlace asociado al disclaimer.
 * @property {string} intervention.disclaimer.link.text - El texto del enlace.
 * @property {string} intervention.disclaimer.link.url - La URL del enlace.
 */

/**
 * Componente que renderiza una intervención de tipo ZRP con un disclaimer.
 *
 * @param {ZrpDisclaimerInterventionProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene la intervención ZRP con un disclaimer.
 */
const ZrpDisclaimerIntervention = ({ intervention = interventionProps }) => {
  const {
    zrp_query_text = null,
    className = '',
    query = '',
    other_sellers_text = '',
    link = {},
  } = intervention?.disclaimer || {};

  const renderedZrpMessage = useMemo(
    () => <ZrpMessage zrp_query_text={zrp_query_text} query={query} />,
    [zrp_query_text, query],
  );

  return (
    <AndesMessageIntervention
      intervention={ANDES_MESSAGE_CONFIG}
      className={classNames(UI_SEARCH_ZRP_DISCLAIMER_NAMESPACE, className)}
    >
      <div>
        {renderedZrpMessage}
        {other_sellers_text && <span>{`${other_sellers_text} `}</span>}
        {link && (
          <Link
            href={link?.url}
            className={`${UI_SEARCH_ZRP_DISCLAIMER_NAMESPACE}__link`}
            isInternal={false}
            title={link?.text}
            aria-label={link?.text}
          >
            {link?.text}
          </Link>
        )}
      </div>
    </AndesMessageIntervention>
  );
};

ZrpDisclaimerIntervention.propTypes = {
  ...ZrpDisclaimerInterventionPropTypes,
};

export default withComponentHandler(ZrpDisclaimerIntervention, { componentName: 'ZrpDisclaimerIntervention' });
