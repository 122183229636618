import React from 'react';

import parse from 'html-react-parser';

import { ZrpMessagePropTypes } from './propTypes/zrp-message-index';
import { UI_SEARCH_ZRP_DISCLAIMER_NAMESPACE } from '../../../constants';
import { withComponentHandler } from '../../../hocs/with-component-handler';
/**
 * @typedef {Object} ZrpMessageProps
 * @property {string} zrp_query_text - El texto de la consulta ZRP.
 * @property {string} [query] - La consulta asociada al mensaje ZRP.
 */

/**
 * Componente que renderiza un mensaje ZRP con texto y consulta opcional.
 *
 * @param {ZrpMessageProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el mensaje ZRP.
 */
const ZrpMessage = ({ zrp_query_text, query }) =>
  query ? (
    <>
      {zrp_query_text}
      <span className={`${UI_SEARCH_ZRP_DISCLAIMER_NAMESPACE}__query`}>{` ${query} `}</span>
    </>
  ) : (
    <span className={`${UI_SEARCH_ZRP_DISCLAIMER_NAMESPACE}__full-message`}>{parse(zrp_query_text)}</span>
  );

ZrpMessage.propTypes = {
  ...ZrpMessagePropTypes,
};

export default withComponentHandler(ZrpMessage, { componentName: 'ZrpMessage' });
