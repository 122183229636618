import React from 'react';
import { string, node, bool, shape } from 'prop-types';

import { Message } from '@andes/message';
import classnames from 'classnames';

const namespace = 'ui-search-andes-message-intervention';
const AndesMessageIntervention = ({ intervention, children, className }) => {
  const { body, is_dismissable = true, hierarchy = 'loud', color = 'accent', title } = intervention.andes_message;

  return (
    <Message
      className={classnames(namespace, className)}
      closable={is_dismissable}
      defaultVisible
      hierarchy={hierarchy}
      title={title}
      color={color}
    >
      {body}
      {children}
    </Message>
  );
};

AndesMessageIntervention.propTypes = {
  children: node,
  className: string,
  intervention: shape({
    andes_message: shape({
      body: string,
      is_dismissable: bool,
      hierarchy: string,
      color: string,
      title: string,
    }),
  }),
};

export default AndesMessageIntervention;
