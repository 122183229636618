import { string, shape } from 'prop-types';

const ZrpDisclaimerInterventionPropTypes = {
  intervention: shape({
    disclaimer: shape({
      className: string.isRequired,
      link: shape({
        url: string.isRequired,
        text: string.isRequired,
      }),
      other_sellers_text: string,
      query: string,
      zrp_query_text: string.isRequired,
    }),
  }),
};

export { ZrpDisclaimerInterventionPropTypes };
